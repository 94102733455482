<template lang="html">
  <div
    class="button"
    v-if="initialData.name"
    :class="`button--${initialData.styles.value}`"
  >
    <button @click="buttonAction" type="button">{{ initialData.name }}</button>
  </div>
</template>

<script>
export default {
  name: 'Button',
  props: {
    initialData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    siteMap() {
      return this.$store.state.global.siteMap;
    },
    appData() {
      return this.$store.state.cookies.appData;
    },
  },
  methods: {
    buttonAction() {
      switch (this.initialData.actions.value) {
        case 'welcome':
          this.$store.commit('cookies/saveAppData', {
            key: 'welcome',
            value: true,
          });
          this.$store.commit('routing/setCurrentRoute', this.siteMap.cookies);
          break;
        case 'cookies_all':
          this.$store.commit('cookies/saveAppData', {
            key: 'cookiesAll',
            value: true,
          });
          this.$store.commit('routing/setCurrentRoute', this.siteMap.home);
          break;
        case 'link':
          this.$store.commit(
            'routing/setCurrentRoute',
            this.initialData.page['post_name']
          );
          break;
        default:
          return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.button {
  width: 90%;
  margin: 0 auto;
  &--button {
    button {
      @include tcl-button;
    }
  }
  &--link {
    button {
      @include tcl-link-button;
    }
  }
}
</style>
